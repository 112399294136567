@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap&subset=latin-ext);

html {
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

*, *::before, *::after {
  box-sizing: inherit;
  margin: 0px;
  padding: 0px;
}

body{
  color: #152839;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.5;
  font-family: 'Source Sans Pro', sans-serif;
}

img{
  max-width: 100%;
}

/* semantic */
h1, h2, h3, h4, h5, h6 {
  margin: 16px 0 8px 0;
}

h2{
  font-size: 28px;
}

p{

}

a{
  color: #152839;
}

a:hover{
  color: #152839;
}

footer a{
  color: #fff;
}

footer a:hover{
  color: #fff;
}

button{
  font-family: 'Source Sans Pro', sans-serif;
  cursor: pointer;
}

/* forms */

/* Grid */
.container-full{
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.container__new{
  width: 100%;
  flex-direction: column;
  padding: 10px;
  margin:auto;
}

.container__small{
  width: 375px;
  flex-direction: column;
  padding: 15px;
  margin: auto;
}

.main{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.container__new__checkout{
  width: 100%;
  flex-direction: column;
  display: flex;
  padding: 10px;
  margin: auto;
}

@media (min-width: 1100px) {
  .container__new{
    width: 1100px;
    flex-direction: column;
  }
}

@media (min-width: 720px) {


  .container__new__checkout{
    width: 1100px;
    flex-direction: row;
    display: flex;
    padding: 0 0 0 0;
  }
  .container__small{
    padding: 0px;
  }
}
.alert__box{

}
.alert{
    border: solid 1px #000;
    font-size: 15px;
    border-radius: 50px;
    padding: 1px 7px;
    display: flex;
    font-weight: 600;
    align-items: center;
    color: #fff;
}

.alert__success{
    background-color: #15CD72;
    border-color: #15CD72;
}

.alert__info{
    background-color: #4bb1cf;
    border-color: #4bb1cf;
}

.alert__warning{
    background-color: #eebf31;
    border-color: #eebf31;
}

.alert__error{
    background-color: #ED4F32;
    border-color: #ED4F32;
}

.alert__icon_path{
    fill: #fff;
}

.alert__icon{
    width: 45px;
    height: 45px;
    padding: 1px 10px 1px 0px;
}
*{
    box-sizing: border-box;
}
.container{
    margin: 30px;
    position: relative;
    padding-top: 56px;
}
label{
    display: inline-block;
    margin-bottom: 0rem;
    font-weight: 600;
}

input{
    overflow: visible;
}

.mt-1{
    margin-top: 10px;
}

.form-control{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #c7c7c7;
    background-color: #fff;
    background-clip: padding-box;
    border-bottom: 2px solid #c7c7c7;
    border-top: none;
    border-left: none;
    border-right: none;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control:focus{
    color: #152839;;
    background-color: #fff;
    border-color: #152839;;
    outline: 0;
    box-shadow: 0px 8px 10px -7px rgba(21,40,57,.25)
}

.form-error{
    font-size: 12px;
    color: red;
    margin: 5px 0px;
}

button{
    width: 100%;
    margin: 15px 0px 5px 0px;
    padding: 15px 20px;
    background-color: #152839;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    box-shadow: 0px 6px 18px 1px rgba(21,40,57,0.5);
    border: solid 4px #152839;
    border-radius: 30px;
    text-transform: uppercase;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

button:hover{
    background-color: #fff;
    color: #152839;
}

.form-group{
    margin-bottom: 1rem;
}

.shortcut{
    text-align:center;
}

.shortcut p {
    margin: 5px 0px 20px 0px;
    font-size: 13px;
}
.cart{
    position: fixed;
    right: 0px;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 5px;
    z-index: 2;
}

.cart__container{
    padding: 6px;
    display: flex;
    flex-direction: column;
    background-color: #152839;
    width: 100%;
    color: #fff;
    border-radius: 35px;
    border: solid 2px #28b8c1;
    outline: none;
}
.activex{

}

.cart__big{
    -webkit-transition: max-height 0.35s ease-in-out;
    transition: max-height 0.35s ease-in-out;
    overflow: hidden;
}
.cart__big__content{
    margin: 15px 10px 30px 10px;
}

.cart__small{
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.cart__icon__box{
    background-color: #28B8C1;
    border-radius: 50px;
    width: 50px;
    height: 50px;
}
.cart__icon{
    max-width: 50px;
    max-height: 50px;
    padding: 10px;
    fill: #152839;
}
.delete__icon{
    max-width: 20px;
    max-height: 30px;
    fill: #fff;
}

.cart__content{
    margin-left: 10px;
}
.cart__title{
    font-size: 18px;
    margin: 10px 0 0 10px;
}
.cart__order{

}
.cart__price{
    margin-left: auto;
    background-color: #28B8C1;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #152839;
    font-weight: 600;
}
.cart__money{

}
.cart__currency{

}

.button__cart{
    width: 100%;
    margin: 5px 0px 5px 0px;
    padding: 15px 20px;
    background-color: #28b8c1;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    box-shadow: 0px 6px 18px 1px rgba(21,40,57,0.5);
    border: solid 4px #152839;
    border-radius: 30px;
    text-transform: uppercase;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.button__cart:hover{
    background-color: #fff;
    color: #152839;
}

.cart__products_checkout{
    display: flex;
    flex-direction: column;
    color:#152839;
}

.cart__products_checkout .cart__single__product{
    color: #152839;
}

.cart__products_checkout .change__amount{
    border: solid 1px #152839;
    color: #152839;
}

.cart__products{
    display: flex;
    flex-direction: column;
}

.cart__single__product{
    padding: 4px 0;
    font-size: 12px;
    color: #fff;
}

.cart__products_checkout .cart__row{
    border-bottom: 1px solid #1528392b;
    padding: 8px 0px 4px 0px;
}

.cart__row{
    display: -moz-flex;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    align-items: center;
    width: 100%;
}

.cart__products_checkout .promo_product{
    border-top: 2px dashed #28b8c182;
    border-bottom: none;
}

.cart__single__product .promo_product__cart{
    border-top: 1px dashed #fff;
    border-bottom: none;
}

.cart__product__amount{
    padding-right: 32px;
    width: 20px;
}

.cart__product__name{
    width: 100%;
    padding: 4px 0;
    word-break: break-all;
    word-break: break-word;
    flex: 1 1;
    font-size: 13px;
}

.cart__product__edit_amount{

}

.change__amount{
    width: auto;
    background-color: unset;
    box-shadow: none;
    border: solid 1px #fff;
    border-radius: 5px;
    line-height: 18px;
    margin: 0px 5px 0px 5px;
    padding: 0px 8px 0px 8px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    cursor: pointer;
}

.cart__product__price{
    text-align: right;
    min-width: 3rem;
    white-space: nowrap;
    font-size: 13px;
    margin-left: 10px;

}

.cart__product__delete{
    width: auto;
    background-color: unset;
    box-shadow: none;
    border: none;
    margin: 0px;
    padding: 6px 0px 6px 6px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    cursor: pointer;
}

.cart__product__delete:hover{
    background-color: unset;
    color: #fff;

}

.cart__promo{

}

.cart__summary{

}

.cart__summary__name{
    width: 100%;
    padding: 4px 0
}

.cart__summary__price{
    min-width: 155px;
    text-align: right;
    white-space: nowrap;
}

.destination__container{
    position: relative;
    margin: 10px;
}

.destination{
    width: 100%;
    padding: 13px 10px 14px 60px;
    border: none;
    margin-bottom: 2px;
    box-shadow: none;
    color: #fff;
    font-size: 14px;
    background-color: #152839;
    border: solid 2px #28b8c1;
    border-radius: 50px;
}

.destination::-webkit-input-placeholder {
    color: #fff;
}

.destination:-ms-input-placeholder {
    color: #fff;
}

.destination::-ms-input-placeholder {
    color: #fff;
}

.destination::placeholder {
    color: #fff;
}

.destination__container .cart__icon__box{
    background-color: #28B8C1;
    border-radius: 50px;
    width: 45px;
    height: 45px;
    position: absolute;
    left: 0px;
    top: 1px;
}

.destination__container .cart__icon{
    max-width: 45px;
    max-height: 45px;
}

.cart__products_checkout .cart__product__name{
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0 20px;
}

.cart__products_checkout .cart__product__amount{
    font-size: 17px;
    font-weight: 600;
}

.cart__products_checkout .change__amount{
    font-size: 17px;
    font-weight: 600;
}

.cart__products_checkout .cart__product__price{
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
}

.form__control__checkout{
    width: 100%;
    padding: 13px 10px 14px 60px;
    border: none;
    margin-bottom: 2px;
    box-shadow: none;
    border-radius: 50px;

    color: #152839;
    font-size: 14px;
    background-color: #ffffff;
    border: solid 2px #152839;
}

@media (min-width: 720px) {

    .cart{
        width: 375px;
        float: right;
        right: 10px;
    }
}
.choose__container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.payment__box .choose__container{
    justify-content: space-between;
}

.choose__button{
    width: 48%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 3px #152839;
    border-radius: 10px;
    padding: 31px 0px;
    font-size: 18px;
    font-weight: 600;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    cursor: pointer;

}

.payment__box .choose__button {
    width: 23%;
    display: flex;
    margin: 0 2% 0 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 3px #152839;
    border-radius: 10px;
    padding: 31px 0px;
    font-size: 18px;
    font-weight: 600;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    cursor: pointer;
}

.payments .choose__button{

    flex-direction: column;
    padding: 10px 0px;
    margin-bottom: 10px;
    text-align: center;
}

.payments .choose__button img{
    width: 60%;
}

.choose__button:hover{
    background-color: #152839;
    color: #fff;
}



.choose__button:hover .choose__icon__path{
    fill: #fff;

}

.payment__title{
    display: inline-flex;
    justify-content: space-between;
}

.payment__logo{
    height: 50px;
    width: 100px;
    margin-top: 10px;
}
.promocode__items{
    display: flex;
}

.promocode__button{
    margin: 0px 0px 15px 0px;
    padding: 10px 10px;
}

.choose__icon{
    width: 38px;
    height: 26px;
    margin-right: 10px;

}

.promocode__item{
    position: relative;
    margin: 10px 0px;
}

.promocode__input{
    width: 100%;
    padding: 13px 10px 14px 60px;
    border: none;
    margin-bottom: 2px;
    box-shadow: none;
    color: #152839;
    font-size: 14px;
    background-color: #fff;
    border: solid 2px #152839;
    border-radius: 50px;
    font-weight: 600;
}

.promocode__input::-webkit-input-placeholder{
    color: #1528396b;
}

.promocode__input:-ms-input-placeholder{
    color: #1528396b;
}

.promocode__input::-ms-input-placeholder{
    color: #1528396b;
}

.promocode__input::placeholder{
    color: #1528396b;
}

.promocode__percent{
    background-color: #152839;
    border-radius: 50px;
    width: 45px;
    height: 45px;
    position: absolute;
    left: 0px;
    top: 1px;
}

.promocode__percent__icon{
    padding: 12px;
    fill: #fff;
    max-width: 45px;
    max-height: 45px;
}

.promocode__check{
    background-color: #152839;
    border-radius: 50px;
    width: 100px;
    display: flex;
    align-items: center;
    height: 46px;
    position: absolute;
    right: -1px;
    top: 0px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    margin: 0px;
    padding: 2px 0px;
    border: none;
}

.promocode__check:hover{
    background-color: #28B8C1;
    color: #fff;
}

.promocode__check__icon{
    padding: 12px;
    fill: #fff;
    max-width: 45px;
    max-height: 45px;
}

.realization .choose__icon__path{
    fill: #fff;
}
.realization .cart__icon__box{
    background-color: #152839;
}

.realization .destination{
    color: #152839;
    font-size: 14px;
    background-color: #ffffff;
    border: solid 2px #152839;
}

.wide__column{
    width: 100%;
    padding: 0 0 0 0;
}

.sidebar__column{
    width: 100%;
    padding: 0 0 0 0;
}
.realization .destination::-webkit-input-placeholder {
    color: #152839;
}
.realization .destination:-ms-input-placeholder {
    color: #152839;
}
.realization .destination::-ms-input-placeholder {
    color: #152839;
}
.realization .destination::placeholder {
    color: #152839;
}
.realization .destination__container{
    margin: 15px 0;
}

.form__group__checkout{
    position: relative;
    margin: 15px 0px;
}

.form__group__checkout .cart__icon__box{
    background-color: #152839;
    border-radius: 50px;
    width: 45px;
    height: 45px;
    position: absolute;
    left: 0px;
    top: 1px;
}


.form__group__checkout .cart__icon {
    max-width: 45px;
    max-height: 45px;
    padding: 10px;
    fill: #152839;
}

.time .choose__button {
    max-height: 95px;
    flex-direction: column;
    padding: 10px 0px;
    text-align: center;
}

.selectTime__button{
    display: flex;
    flex-direction: row;
    width: 80%;
    align-items: center;
}

.selectTime__param{
    display: flex;
    flex-direction: row;
    width: 60%;
    align-items: center;
}
.selectTime__separator{
    display: flex;
    flex-direction: row;
    width: 60%;
    align-items: center;
}
.selectTime__arrow_container{
    height: 20px;
}
.selectTime__arrow_path{
    fill: #152839;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.choose__button:hover .selectTime__arrow_path{
    fill: #fff;
}
.selectTime__arrow_up{
    height: 20px;
    width: 22px;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}
.selectTime__arrow_down{
    height: 20px;
    width: 22px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}
.choose__button .choose__icon{
    width: 30px;
    height: 30px;
}
.choose__button .choose__icon_path{
 fill: #152839;
 -webkit-transition: all .3s ease-in-out;
 transition: all .3s ease-in-out;
}

.time .checked .choose__icon_path{
    fill: #fff!important;
   }

.choose__button:hover .choose__icon_path{
    fill: #fff;
   }

.checked{
    background-color: #152839;
    color: #fff;
}
.checked .choose__icon__path{
    fill: #fff;
}

@media (min-width: 720px) {

    .cart{
        width: 375px;
        float: right;
        margin: 0 10px 10px 0;
    }
    .wide__column{
        width: 70%;
        padding: 0 10px 0 0;
    }

    .sidebar__column{
        width: 30%;
        padding: 0 0 0 10px;
    }
}
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #152839;
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
  }
  @-webkit-keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @-webkit-keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }

.cmn-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
  }
  .cmn-toggle + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  input.cmn-toggle-round + label {
    padding: 2px;
    width: 90px;
    height: 40px;
    background-color: #28b8c1;
    border-radius: 60px;
  }
  input.cmn-toggle-round + label:before,
  input.cmn-toggle-round + label:after {
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    content: "";
  }
  input.cmn-toggle-round + label:before {
    right: 1px;
    background-color: #28b8c1;
    border-radius: 60px;
    -webkit-transition: background 0.4s;
    transition: background 0.4s;
  }
  input.cmn-toggle-round + label:after {
    width: 38px;
    background-color: #152839;
    border-radius: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    -webkit-transition: margin 0.4s;
    transition: margin 0.4s;
  }
  input.cmn-toggle-round:checked + label:before {
    background-color: #28b8c1;
  }
  input.cmn-toggle-round:checked + label:after {
    margin-left: 49px;
  }

  .option-1{
    position: absolute;
    left: 10px;
    top: 5px;
    color: #fff;
    font-size: 20px;
  }

  .option-2{
    position: absolute;
    right: 10px;
    top: 5px;
    color: #fff;
    font-size: 20px;

  }

  .option__icon{
      height: 30px;
      width: 26px;

  }

  .choose__icon__path{
    fill: rgb(21, 40, 57);
  }

  .cart__realization{
      display: flex;
      flex-direction: row;
      margin: 10px 0px;
      padding-left: 50px;
  }

  .realization__name{
      font-size: 18px;
      padding-left: 20px;
      line-height: 40px;;
  }
.open_sign{
    display: flex;
    width: 16px;
    height: 16px;
    background-color: rgb(0, 206, 123);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin: 5px 0 0 8px;
}
.open_sign__inner{
    display: flex;
    width: 8px;
    height: 8px;
    background-color: #00FF99;
    border-radius: 50%;
}
.open_sign_closed{
    display: flex;
    width: 16px;
    height: 16px;
    background-color: rgba(218, 0, 0, 0.46);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin: 5px 0 0 8px;
}
.open_sign__inner_closed{
    display: flex;
    width: 8px;
    height: 8px;
    background-color: #b50000;
    border-radius: 50%;
}
.hero{
    align-items: center;
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,1)), color-stop(30%, rgba(255,255,255,0)), color-stop(41%, rgba(255,255,255,0))), url(/static/media/hero5.959cf48c.jpg);
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%, rgba(255,255,255,0) 41%), url(/static/media/hero5.959cf48c.jpg);
    background-size: cover;
    padding: 70px 20px 20px 20px;
}

.hero__logo{
    margin: auto;
    display: flex;
    justify-content: center;
    width: 155px;
    height: 155px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 100px;
    align-items: center;
}

.hero__title{
    color: #fff;
    text-align: center;
    margin: 15px 0;
    line-height: 1.2;
    font-weight: 600;
}

.hero__title--small{
    font-size: 14px;
}

.hero__heading{
    display: flex;
    justify-content: center;
    align-items: center;

}

.hero__logo_img{
    width: 100%;
    padding: 15px;
}

.hero__choose{
    text-align: center;

}

.dropdownMenu{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
}
.dropdownMenu__button{
    margin: 0px;
    padding: 8px 20px;
    border-radius: 20px;
    display: inline-flex;
}

.dropdownMenu__button:hover,
.active {
  background-color: #fff;
  color :#152839;
}
.dropdownMenu__button:hover
.dropdownMenu__icon{
    fill: #152839;
}

.dropdownMenu__options{
    background-color: #fff;
    border-radius: 0px 0px 10px 10px;
    display: block;
    border: solid 2px #152839;
    overflow: hidden;
    -webkit-transition: max-height 0.6s ease;
    transition: max-height 0.6s ease;
    margin-bottom: 0px;
}
.dropdownMenu__option{
    cursor: pointer;
    padding: 4px;
    font-weight: 600;
    -webkit-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease;
    overflow: hidden;
    border-radius: 10px;
}

.dropdownMenu__icon,
.active{
    fill: #152839;
}
.dropdownMenu__icon{
    fill: #fff;
    -webkit-transition: -webkit-transform 0.6s ease;
    transition: -webkit-transform 0.6s ease;
    transition: transform 0.6s ease;
    transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

.dropdownMenu__option:hover{
    background-color: rgb(211, 211, 211);
}

  /* Style to rotate icon when state is active */
  .rotate {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
@media (max-width: 720px) {
    .hero__logo{
        opacity: 1;
    }
}
.login_btn{
    background-color: #152839;
    border: solid 2px #152839;
    border-radius: 50px;
    width: 100%;
    -webkit-transition: ease-in-out 0.3s;
    transition: ease-in-out 0.3s;
    margin: 0px 0px 0px 0px;
    padding: 2px 20px;
    align-items: center;
    display: flex;
}

.login_btn:hover, .login_btn:active{
    background-color: #fff;
}

.login_btn__text{
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 0;
}
.login_btn:hover .login_btn__text, .login_btn:active .login_btn__text{
    color:#152839;
}

.login__icon{
    max-width: 18px;
    max-height: 18px;
    padding: 0px;
    margin-left: 10px;
}

.login__icon__path{
    fill: #fff;
}

.login_btn:hover .login__icon__path{
    fill: #152839;
}
a{
    text-decoration: none;
}

.social_icon{
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    background-color: aquamarine;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social_icon:first-child{
    margin: 0 5px 0 0;
}

.social_icon__image{
    height: 14px;
}

.header{
    position: absolute;
    top: 0px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}
.header__content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header__item{
    padding: 12px 0px;
    display: flex;
}
footer {
  background-color: #152839;
  color: #fff;
  margin-top: 40px;
}
.footer__content {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer__item {
  padding: 12px 0px;
  display: flex;
  align-items: center;
}
.footer__container {
  padding: 0px 15px;
}

.copy .footer__container {
  color: #fff;
  font-size: 13px;
}

.footer__logo {
  width: 100px;
  margin: auto;
  display: flex;
  justify-content: center;
}

.fot__link {
  margin: 0 10px;
}

@media (max-width: 768px) {
  .footer__content {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .footer__item {
    padding: 10px 0px;
    display: flex;
    align-items: center;
  }
  .footer__content {
    flex-direction: column;
  }

  .footer__item {
    justify-content: center;
  }
}

.accordion__section {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  /* Style the buttons that are used to open and close the accordion panel */
  .accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 8px 6px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    -webkit-transition: background-color 0.6s ease;
    transition: background-color 0.6s ease;
    box-shadow: none;
    margin: 0;
    border-radius: 0;
    background-color: #fff;
    border-bottom: 1px solid #00000021;

  }

  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .accordion:hover,
  .active {
    background-color: #28b8c182;
  }

  /* #1528391c */

  /* Style the accordion content title */
  .accordion__title {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
  }

  .accordion__item__title{
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 15px;
    text-transform: uppercase;
  }

  /* Style the accordion chevron icon */
  .accordion__icon {
    margin-left: auto;
    -webkit-transition: -webkit-transform 0.6s ease;
    transition: -webkit-transform 0.6s ease;
    transition: transform 0.6s ease;
    transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  }

  /* Style to rotate icon when state is active */
  .rotate {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }

  /* Style the accordion content panel. Note: hidden by default */
  .accordion__content {
    background-color: white;
    overflow: hidden;
    -webkit-transition: max-height 0.6s ease;
    transition: max-height 0.6s ease;
    margin-bottom: 0px;

  }
  .accordion__item{
    display: flex;
      align-items: center;
    padding: 15px 15px 10px 15px;
  }

  /* Style the accordion content text */
  .accordion__text {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 18px;
  }

.accordion__photo{
  max-width: 50px;
}

.accordion__photo_min{
  max-width: 50px;
  min-width: 50px;
}

  .accordion__price{
      margin-left: auto;
      color: #fff;
      background-color: #152839;
      border-radius: 50px;
      height: 45px;
      width: 45px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 10px 0 auto;
      box-shadow: 0 0 0 7px rgba(0,0,0,0.1);
      display: flex;
      font-weight: 600;
      flex-direction: column;
      -webkit-transition: box-shadow 0.5s ease;
      transition: box-shadow 0.5s ease;
      min-width: 45px;
  }

.accordion__price:hover{
  box-shadow: 0 0 0 7px #28b8c14f;
}

.price__currency{
  font-size: 11px;
  letter-spacing: 0.1em;
  line-height: 1.4em;
}

  .items__image{
    border-radius: 50%;
  }

  .accordion__data{
    padding: 0 0 0 10px;
  }

  .accordion__desc{
    font-weight: 500;
    font-size: 14px;
  }

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #152839;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip-left {
  top: 10px;
  bottom: auto;
  right: 118%;
}
.tooltip-left::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #152839;
}
/* 720px lub 960px */

  @media (min-width: 720px) {
    .accordion__price{
      height: 58px;
      width: 58px;
      font-size: 16px;
      font-weight: 600;
    }

    .accordion__photo{
      max-width: 78px;
    }

    .accordion__photo_min{
      max-width: 62px;
    }

    .accordion__title{
      font-size: 18px;
    }
    .accordion__item__title{
      font-size: 15px;
      font-weight: 600;
    }
    .accordion__desc{
      font-weight: 500;
      font-size: 15px;
    }
    .accordion__item{
      padding: 15px 30px 10px 30px;
    }
    .accordion__data{
      padding: 0 0 0 20px;
    }
  }
.accordion__section_sec {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
  }

.order__history__icon_path{
    fill: #444;
}

.order__history__icon{
    width: 50px;
    height: 50%;
    padding: 8px;
}

  /* Style the buttons that are used to open and close the accordion panel */
  .accordion_sec {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 8px 6px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    -webkit-transition: background-color 0.6s ease;
    transition: background-color 0.6s ease;
    box-shadow: none;
    margin: 0;
    border-radius: 0;
    background-color: #fff;
    border-bottom: 1px solid #00000021;

  }

  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .accordion_sec:hover,
  .active {
    background-color: #fff;
  }

  /* #1528391c */

  /* Style the accordion content title */
  .accordion__title {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
  }

  /* Style the accordion chevron icon */
  .accordion__icon {
    margin-left: auto;
    -webkit-transition: -webkit-transform 0.6s ease;
    transition: -webkit-transform 0.6s ease;
    transition: transform 0.6s ease;
    transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  }

  /* Style to rotate icon when state is active */
  .rotate {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }

  /* Style the accordion content panel. Note: hidden by default */
  .accordion__content {
    background-color: white;
    overflow: hidden;
    -webkit-transition: max-height 0.6s ease;
    transition: max-height 0.6s ease;
    margin-bottom: 0px;

  }
  .accordion__item_sec{
    display: flex;
    align-items: center;
    font-size: 18px;
    justify-content: center;
    padding: 15px 5px 15px 5px;
  }

  /* Style the accordion content text */
  .accordion__text {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 18px;
  }

.accordion__photo{
  max-width: 50px;
}

.accordion__photo_min{
  max-width: 50px;
}

  .accordion__price{
      margin-left: auto;
      color: #fff;
      background-color: #152839;
      border-radius: 50px;
      height: 45px;
      width: 45px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 10px 0 auto;
      box-shadow: 0 0 0 7px rgba(0,0,0,0.1);
      display: flex;
      font-weight: 600;
      flex-direction: column;
      -webkit-transition: box-shadow 0.5s ease;
      transition: box-shadow 0.5s ease;
  }

.accordion__price:hover{
  box-shadow: 0 0 0 7px #28b8c14f;
}

.accordion__item_data_number{
  min-width: 45px;
  margin-left: 10px;
  margin-right: 20px;
  color: #fff;
  background-color: #152839;
  border-radius: 50px;
  height: 45px;
  width: 45px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 7px rgba(0,0,0,0.1);
  display: flex;
  font-weight: 600;
  flex-direction: column;
  -webkit-transition: box-shadow 0.5s ease;
  transition: box-shadow 0.5s ease;
}
.accordion__item_data_list{
  margin-left: 20px;
  margin-right: 40px;
}

.accordion__item_data_button{
  margin: 0 10px 0 auto;
  background-color: #152839;
  border: solid 2px #152839;
  border-radius: 50px;
  -webkit-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
  padding: 2px 20px;
  align-items: center;
  width: auto;
  display: flex;
}

.price__currency{
  font-size: 11px;
  letter-spacing: 0.1em;
  line-height: 1.4em;
}

  .items__image{
    border-radius: 50%;
  }

  .accordion__data_sec{
    padding: 0 0 0 10px;
    text-align: left;
  }

  .accordion__desc{
    font-weight: 500;
    font-size: 14px;
  }

/* 720px lub 960px */

  @media (min-width: 720px) {
    .accordion__price{
      height: 58px;
      width: 58px;
      font-size: 16px;
      font-weight: 600;
    }

    .accordion__photo{
      max-width: 78px;
    }

    .accordion__photo_min{
      max-width: 62px;
    }

    .accordion__title{
      font-size: 19px;
    }
    .accordion__desc{
      font-weight: 500;
      font-size: 16px;
    }

  }
.info__container{
    width: 100%;
    flex-direction: row;
    display: flex;

}

.accordion_sec:hover,
.active {
  background-color: #fff;
}

.info{
    display: flex;
    justify-content: center;
    box-shadow: 0px 6px 18px 1px rgba(21,40,57,0.5);
}
.info p{
    font-size: 15px;
}
.info__box{
    position: relative;
    width: 20%;
    padding: 20px 20px 20px 20px;
}

.info__box:last-child{
    border-right: none;
}

.info__box:after{
    content: '';
    width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 80px 80px 0;
  border-color: transparent #152839 transparent transparent;
  right: 0;
  top: 0;
  position: absolute;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.info__box__icon{
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 1;

}
.info__box__path{
    fill: #fff;
}

.info__icon_small{
    max-width: 20px;
    max-height: 20px;
    padding: 2px;
    margin-right: 5px
}

.info__icon_small__path{
    fill: #152839;
}

.info__data{
    display: flex;
    align-items: center;
}

.info__icon{
    max-width: 40px;
    max-height: 40px;
    padding: 5px;
}

.info__box {
    width: 100%;
}

.info__mobile .accordion_sec{
    border-bottom: none;
    margin-top: 0px;
}

.info__mobile .accordion_sec .info__box__path {
    fill: #152839;
}

.info__mobile .accordion__data .info__box__path {
    fill: #152839;
}

.info__mobile .info__box:hover:after{
    border-color: transparent #152839 transparent transparent;
}

.info__mobile .accordion__content {
    margin-bottom: 0px;
}

.info__mobile{
    display: block;
    width: 100%;
}

.info__desktop{
    display: none;
}


@media (min-width: 720px) {

    .info__mobile{
        display: none;
    }

    .info__desktop{
        display: block;
        width: 100%;
    }
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9999;

  }

  .modal-main{
    text-align: center;
  }

  .modal .modal-main {
    position: fixed;
    background: white;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    max-width:94%;
    height: auto;
    top: 50%;
    left: 50%;
    border-radius: 20px;
    padding: 25px;
    -webkit-transform: translate(-50.1%, -50.12%);
            transform: translate(-50.1%, -50.12%);
}

  .modal__close{
    position: absolute;
    top: -15px;
    right: -15px;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    background-color: #152839;
    cursor: pointer;
  }

  .modal__close_icon{
    width: 40%;
    height: 100%;
  }

  .modal__close_icon_path{
    fill: #fff;
  }

  .modal h2{
    font-size: 20px;
    margin: 0.2rem;
  }

  .modal h3{
    font-size: 16px;
    margin: 0.2rem;
  }

  .modal button{
    padding: 10px 20px;
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }

  .orderStatus__container{
      display: flex;
      flex-direction: column;
      padding: 5px 0px;
      justify-content: center;
  }

  .orderStatus__item{
    margin: 5px 10px;
  }



  .orderStatus__icon{
    max-width: 65px;
    max-height: 65px;
    padding: 15px;
    border: dashed 2px #152839;
    border-radius: 100%;
  }

  .orderStatus__icon_path{
    fill: #152839;
}

.done{
    border-color: green;
  }

  .done .orderStatus__icon_path{
      fill: green;
  }
  .dotted::before{
    display: none;
    content: "....";
    position: relative;
    letter-spacing: 6px;
    font-size: 28px;
    top: -43px;
    color: #152839;
    margin-left: 10px;
    margin-right: 2px;
}

.dotted_green::before{
  display: none;
  content: "....";
  position: relative;
  letter-spacing: 6px;
  font-size: 28px;
  top: -43px;
  color: green;
  margin-left: 10px;
  margin-right: 2px;
}

.trackOrder__button{
  position: fixed;

    bottom: 25px;
    display: flex;
    align-items: center;
    width: 96%;
    padding: 5px;
    z-index: 2;
    margin: 0 0 0 2%;
    border: solid 2px #28b8c1;
}

.trackOrder__button:hover{
  background-color: #152839;
  color:#fff;
}

.trackOrder__inner{
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
}

.trackOrder__title{
  font-size: 18px;
  margin: 13px 0 0 18px;
  text-transform: none;
  text-transform: initial;
  font-weight: 400;
}

@media (min-width: 1200px) {

  .modal .modal-main {
    width: 60%;
  }

}

@media (min-width: 992px) {


}

@media (min-width: 768px) {

  .modal h2{
    font-size: 28px;
    margin: 1rem 0 .5rem 0;
  }

  .modal h3{
    font-size: 1rem;
    margin: 1rem 0 .5rem 0;
  }

  .modal button{
    padding: 15px 20px;
  }

  .modal .modal-main {
    position:fixed;
    background: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    top:50%;
    left:50%;
    border-radius: 20px;
    padding: 25px;
    -webkit-transform: translate(-50.1%, -50.12%);
    transform: translate(-50.1%, -50.12%);
  }

  .orderStatus__icon{
    max-width: 95px;
    max-height: 95px;
    padding: 25px;
    border: dashed 2px #152839;
    border-radius: 100%;
  }

  .orderStatus__container{
    flex-direction: row;
  }

  .trackOrder__button{
      width: 365px;
      float: right;
      margin: 0 11px 11px 0;
      right: 11px;
  }
  .trackOrder__button:hover{
    background-color: #152839;
    color: #fff;
}

  .trackOrder__icon{
    background-color: #28B8C1;
    border-radius: 50px;
    width: 50px;
    height: 50px;
  }

  .trackOrder__icon_path{
    fill: #152839;
  }
  .trackOrder__title{
    font-size: 18px;
    margin: 13px 0 0 18px;
    text-transform: none;
    text-transform: initial;
    font-weight: 400;
  }

  .trackOrder__inner{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    width: 100%;
  }

  .done{
    border-color: green;
  }
}
