.cmn-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
  }
  .cmn-toggle + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
  }
  input.cmn-toggle-round + label {
    padding: 2px;
    width: 90px;
    height: 40px;
    background-color: #28b8c1;
    border-radius: 60px;
  }
  input.cmn-toggle-round + label:before,
  input.cmn-toggle-round + label:after {
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    content: "";
  }
  input.cmn-toggle-round + label:before {
    right: 1px;
    background-color: #28b8c1;
    border-radius: 60px;
    transition: background 0.4s;
  }
  input.cmn-toggle-round + label:after {
    width: 38px;
    background-color: #152839;
    border-radius: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: margin 0.4s;
  }
  input.cmn-toggle-round:checked + label:before {
    background-color: #28b8c1;
  }
  input.cmn-toggle-round:checked + label:after {
    margin-left: 49px;
  }

  .option-1{
    position: absolute;
    left: 10px;
    top: 5px;
    color: #fff;
    font-size: 20px;
  }

  .option-2{
    position: absolute;
    right: 10px;
    top: 5px;
    color: #fff;
    font-size: 20px;

  }

  .option__icon{
      height: 30px;
      width: 26px;

  }

  .choose__icon__path{
    fill: rgb(21, 40, 57);
  }

  .cart__realization{
      display: flex;
      flex-direction: row;
      margin: 10px 0px;
      padding-left: 50px;
  }

  .realization__name{
      font-size: 18px;
      padding-left: 20px;
      line-height: 40px;;
  }