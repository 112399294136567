a{
    text-decoration: none;
}

.social_icon{
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    background-color: aquamarine;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social_icon:first-child{
    margin: 0 5px 0 0;
}

.social_icon__image{
    height: 14px;
}