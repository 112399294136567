.open_sign{
    display: flex;
    width: 16px;
    height: 16px;
    background-color: rgb(0, 206, 123);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin: 5px 0 0 8px;
}
.open_sign__inner{
    display: flex;
    width: 8px;
    height: 8px;
    background-color: #00FF99;
    border-radius: 50%;
}
.open_sign_closed{
    display: flex;
    width: 16px;
    height: 16px;
    background-color: rgba(218, 0, 0, 0.46);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin: 5px 0 0 8px;
}
.open_sign__inner_closed{
    display: flex;
    width: 8px;
    height: 8px;
    background-color: #b50000;
    border-radius: 50%;
}