.login_btn{
    background-color: #152839;
    border: solid 2px #152839;
    border-radius: 50px;
    width: 100%;
    transition: ease-in-out 0.3s;
    margin: 0px 0px 0px 0px;
    padding: 2px 20px;
    align-items: center;
    display: flex;
}

.login_btn:hover, .login_btn:active{
    background-color: #fff;
}

.login_btn__text{
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 0;
}
.login_btn:hover .login_btn__text, .login_btn:active .login_btn__text{
    color:#152839;
}

.login__icon{
    max-width: 18px;
    max-height: 18px;
    padding: 0px;
    margin-left: 10px;
}

.login__icon__path{
    fill: #fff;
}

.login_btn:hover .login__icon__path{
    fill: #152839;
}