@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap&subset=latin-ext');

html {
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

*, *::before, *::after {
  box-sizing: inherit;
  margin: 0px;
  padding: 0px;
}

body{
  color: #152839;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.5;
  font-family: 'Source Sans Pro', sans-serif;
}

img{
  max-width: 100%;
}

/* semantic */
h1, h2, h3, h4, h5, h6 {
  margin: 16px 0 8px 0;
}

h2{
  font-size: 28px;
}

p{

}

a{
  color: #152839;
}

a:hover{
  color: #152839;
}

footer a{
  color: #fff;
}

footer a:hover{
  color: #fff;
}

button{
  font-family: 'Source Sans Pro', sans-serif;
  cursor: pointer;
}

/* forms */

/* Grid */
.container-full{
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.container__new{
  width: 100%;
  flex-direction: column;
  padding: 10px;
  margin:auto;
}

.container__small{
  width: 375px;
  flex-direction: column;
  padding: 15px;
  margin: auto;
}

.main{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.container__new__checkout{
  width: 100%;
  flex-direction: column;
  display: flex;
  padding: 10px;
  margin: auto;
}

@media (min-width: 1100px) {
  .container__new{
    width: 1100px;
    flex-direction: column;
  }
}

@media (min-width: 720px) {


  .container__new__checkout{
    width: 1100px;
    flex-direction: row;
    display: flex;
    padding: 0 0 0 0;
  }
  .container__small{
    padding: 0px;
  }
}