footer {
  background-color: #152839;
  color: #fff;
  margin-top: 40px;
}
.footer__content {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer__item {
  padding: 12px 0px;
  display: flex;
  align-items: center;
}
.footer__container {
  padding: 0px 15px;
}

.copy .footer__container {
  color: #fff;
  font-size: 13px;
}

.footer__logo {
  width: 100px;
  margin: auto;
  display: flex;
  justify-content: center;
}

.fot__link {
  margin: 0 10px;
}

@media (max-width: 768px) {
  .footer__content {
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .footer__item {
    padding: 10px 0px;
    display: flex;
    align-items: center;
  }
  .footer__content {
    flex-direction: column;
  }

  .footer__item {
    justify-content: center;
  }
}
