.info__container{
    width: 100%;
    flex-direction: row;
    display: flex;

}

.accordion_sec:hover,
.active {
  background-color: #fff;
}

.info{
    display: flex;
    justify-content: center;
    box-shadow: 0px 6px 18px 1px rgba(21,40,57,0.5);
}
.info p{
    font-size: 15px;
}
.info__box{
    position: relative;
    width: 20%;
    padding: 20px 20px 20px 20px;
}

.info__box:last-child{
    border-right: none;
}

.info__box:after{
    content: '';
    width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 80px 80px 0;
  border-color: transparent #152839 transparent transparent;
  right: 0;
  top: 0;
  position: absolute;
  transition: all 0.35s ease-in-out;
}
.info__box__icon{
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 1;

}
.info__box__path{
    fill: #fff;
}

.info__icon_small{
    max-width: 20px;
    max-height: 20px;
    padding: 2px;
    margin-right: 5px
}

.info__icon_small__path{
    fill: #152839;
}

.info__data{
    display: flex;
    align-items: center;
}

.info__icon{
    max-width: 40px;
    max-height: 40px;
    padding: 5px;
}

.info__box {
    width: 100%;
}

.info__mobile .accordion_sec{
    border-bottom: none;
    margin-top: 0px;
}

.info__mobile .accordion_sec .info__box__path {
    fill: #152839;
}

.info__mobile .accordion__data .info__box__path {
    fill: #152839;
}

.info__mobile .info__box:hover:after{
    border-color: transparent #152839 transparent transparent;
}

.info__mobile .accordion__content {
    margin-bottom: 0px;
}

.info__mobile{
    display: block;
    width: 100%;
}

.info__desktop{
    display: none;
}


@media (min-width: 720px) {

    .info__mobile{
        display: none;
    }

    .info__desktop{
        display: block;
        width: 100%;
    }
}