*{
    box-sizing: border-box;
}
.container{
    margin: 30px;
    position: relative;
    padding-top: 56px;
}
label{
    display: inline-block;
    margin-bottom: 0rem;
    font-weight: 600;
}

input{
    overflow: visible;
}

.mt-1{
    margin-top: 10px;
}

.form-control{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #c7c7c7;
    background-color: #fff;
    background-clip: padding-box;
    border-bottom: 2px solid #c7c7c7;
    border-top: none;
    border-left: none;
    border-right: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control:focus{
    color: #152839;;
    background-color: #fff;
    border-color: #152839;;
    outline: 0;
    box-shadow: 0px 8px 10px -7px rgba(21,40,57,.25)
}

.form-error{
    font-size: 12px;
    color: red;
    margin: 5px 0px;
}

button{
    width: 100%;
    margin: 15px 0px 5px 0px;
    padding: 15px 20px;
    background-color: #152839;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    -webkit-box-shadow: 0px 6px 18px 1px rgba(21,40,57,0.5);
    -moz-box-shadow: 0px 6px 18px 1px rgba(21,40,57,0.5);
    box-shadow: 0px 6px 18px 1px rgba(21,40,57,0.5);
    border: solid 4px #152839;
    border-radius: 30px;
    text-transform: uppercase;
    transition: all .3s ease-in-out;
}

button:hover{
    background-color: #fff;
    color: #152839;
}

.form-group{
    margin-bottom: 1rem;
}

.shortcut{
    text-align:center;
}

.shortcut p {
    margin: 5px 0px 20px 0px;
    font-size: 13px;
}