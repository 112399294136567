.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9999;

  }

  .modal-main{
    text-align: center;
  }

  .modal .modal-main {
    position: fixed;
    background: white;
    width: -moz-fit-content;
    width: fit-content;
    max-width:94%;
    height: auto;
    top: 50%;
    left: 50%;
    border-radius: 20px;
    padding: 25px;
    transform: translate(-50.1%, -50.12%);
}

  .modal__close{
    position: absolute;
    top: -15px;
    right: -15px;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    background-color: #152839;
    cursor: pointer;
  }

  .modal__close_icon{
    width: 40%;
    height: 100%;
  }

  .modal__close_icon_path{
    fill: #fff;
  }

  .modal h2{
    font-size: 20px;
    margin: 0.2rem;
  }

  .modal h3{
    font-size: 16px;
    margin: 0.2rem;
  }

  .modal button{
    padding: 10px 20px;
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }

  .orderStatus__container{
      display: flex;
      flex-direction: column;
      padding: 5px 0px;
      justify-content: center;
  }

  .orderStatus__item{
    margin: 5px 10px;
  }



  .orderStatus__icon{
    max-width: 65px;
    max-height: 65px;
    padding: 15px;
    border: dashed 2px #152839;
    border-radius: 100%;
  }

  .orderStatus__icon_path{
    fill: #152839;
}

.done{
    border-color: green;
  }

  .done .orderStatus__icon_path{
      fill: green;
  }
  .dotted::before{
    display: none;
    content: "....";
    position: relative;
    letter-spacing: 6px;
    font-size: 28px;
    top: -43px;
    color: #152839;
    margin-left: 10px;
    margin-right: 2px;
}

.dotted_green::before{
  display: none;
  content: "....";
  position: relative;
  letter-spacing: 6px;
  font-size: 28px;
  top: -43px;
  color: green;
  margin-left: 10px;
  margin-right: 2px;
}

.trackOrder__button{
  position: fixed;

    bottom: 25px;
    display: flex;
    align-items: center;
    width: 96%;
    padding: 5px;
    z-index: 2;
    margin: 0 0 0 2%;
    border: solid 2px #28b8c1;
}

.trackOrder__button:hover{
  background-color: #152839;
  color:#fff;
}

.trackOrder__inner{
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: 100%;
}

.trackOrder__title{
  font-size: 18px;
  margin: 13px 0 0 18px;
  text-transform: initial;
  font-weight: 400;
}

@media (min-width: 1200px) {

  .modal .modal-main {
    width: 60%;
  }

}

@media (min-width: 992px) {


}

@media (min-width: 768px) {

  .modal h2{
    font-size: 28px;
    margin: 1rem 0 .5rem 0;
  }

  .modal h3{
    font-size: 1rem;
    margin: 1rem 0 .5rem 0;
  }

  .modal button{
    padding: 15px 20px;
  }

  .modal .modal-main {
    position:fixed;
    background: white;
    width: fit-content;
    height: auto;
    top:50%;
    left:50%;
    border-radius: 20px;
    padding: 25px;
    -webkit-transform: translate(-50.1%, -50.12%);
    transform: translate(-50.1%, -50.12%);
  }

  .orderStatus__icon{
    max-width: 95px;
    max-height: 95px;
    padding: 25px;
    border: dashed 2px #152839;
    border-radius: 100%;
  }

  .orderStatus__container{
    flex-direction: row;
  }

  .trackOrder__button{
      width: 365px;
      float: right;
      margin: 0 11px 11px 0;
      right: 11px;
  }
  .trackOrder__button:hover{
    background-color: #152839;
    color: #fff;
}

  .trackOrder__icon{
    background-color: #28B8C1;
    border-radius: 50px;
    width: 50px;
    height: 50px;
  }

  .trackOrder__icon_path{
    fill: #152839;
  }
  .trackOrder__title{
    font-size: 18px;
    margin: 13px 0 0 18px;
    text-transform: initial;
    font-weight: 400;
  }

  .trackOrder__inner{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    width: 100%;
  }

  .done{
    border-color: green;
  }
}