.choose__container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.payment__box .choose__container{
    justify-content: space-between;
}

.choose__button{
    width: 48%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 3px #152839;
    border-radius: 10px;
    padding: 31px 0px;
    font-size: 18px;
    font-weight: 600;
    transition: all .3s ease-in-out;
    cursor: pointer;

}

.payment__box .choose__button {
    width: 23%;
    display: flex;
    margin: 0 2% 0 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 3px #152839;
    border-radius: 10px;
    padding: 31px 0px;
    font-size: 18px;
    font-weight: 600;
    transition: all .3s ease-in-out;
    cursor: pointer;
}

.payments .choose__button{

    flex-direction: column;
    padding: 10px 0px;
    margin-bottom: 10px;
    text-align: center;
}

.payments .choose__button img{
    width: 60%;
}

.choose__button:hover{
    background-color: #152839;
    color: #fff;
}



.choose__button:hover .choose__icon__path{
    fill: #fff;

}

.payment__title{
    display: inline-flex;
    justify-content: space-between;
}

.payment__logo{
    height: 50px;
    width: 100px;
    margin-top: 10px;
}
.promocode__items{
    display: flex;
}

.promocode__button{
    margin: 0px 0px 15px 0px;
    padding: 10px 10px;
}

.choose__icon{
    width: 38px;
    height: 26px;
    margin-right: 10px;

}

.promocode__item{
    position: relative;
    margin: 10px 0px;
}

.promocode__input{
    width: 100%;
    padding: 13px 10px 14px 60px;
    border: none;
    margin-bottom: 2px;
    box-shadow: none;
    color: #152839;
    font-size: 14px;
    background-color: #fff;
    border: solid 2px #152839;
    border-radius: 50px;
    font-weight: 600;
}

.promocode__input::placeholder{
    color: #1528396b;
}

.promocode__percent{
    background-color: #152839;
    border-radius: 50px;
    width: 45px;
    height: 45px;
    position: absolute;
    left: 0px;
    top: 1px;
}

.promocode__percent__icon{
    padding: 12px;
    fill: #fff;
    max-width: 45px;
    max-height: 45px;
}

.promocode__check{
    background-color: #152839;
    border-radius: 50px;
    width: 100px;
    display: flex;
    align-items: center;
    height: 46px;
    position: absolute;
    right: -1px;
    top: 0px;
    transition: all .3s ease-in-out;
    margin: 0px;
    padding: 2px 0px;
    border: none;
}

.promocode__check:hover{
    background-color: #28B8C1;
    color: #fff;
}

.promocode__check__icon{
    padding: 12px;
    fill: #fff;
    max-width: 45px;
    max-height: 45px;
}

.realization .choose__icon__path{
    fill: #fff;
}
.realization .cart__icon__box{
    background-color: #152839;
}

.realization .destination{
    color: #152839;
    font-size: 14px;
    background-color: #ffffff;
    border: solid 2px #152839;
}

.wide__column{
    width: 100%;
    padding: 0 0 0 0;
}

.sidebar__column{
    width: 100%;
    padding: 0 0 0 0;
}
.realization .destination::placeholder {
    color: #152839;
}
.realization .destination__container{
    margin: 15px 0;
}

.form__group__checkout{
    position: relative;
    margin: 15px 0px;
}

.form__group__checkout .cart__icon__box{
    background-color: #152839;
    border-radius: 50px;
    width: 45px;
    height: 45px;
    position: absolute;
    left: 0px;
    top: 1px;
}


.form__group__checkout .cart__icon {
    max-width: 45px;
    max-height: 45px;
    padding: 10px;
    fill: #152839;
}

.time .choose__button {
    max-height: 95px;
    flex-direction: column;
    padding: 10px 0px;
    text-align: center;
}

.selectTime__button{
    display: flex;
    flex-direction: row;
    width: 80%;
    align-items: center;
}

.selectTime__param{
    display: flex;
    flex-direction: row;
    width: 60%;
    align-items: center;
}
.selectTime__separator{
    display: flex;
    flex-direction: row;
    width: 60%;
    align-items: center;
}
.selectTime__arrow_container{
    height: 20px;
}
.selectTime__arrow_path{
    fill: #152839;
    transition: all .3s ease-in-out;
}
.choose__button:hover .selectTime__arrow_path{
    fill: #fff;
}
.selectTime__arrow_up{
    height: 20px;
    width: 22px;
    transform: rotate(-90deg);
}
.selectTime__arrow_down{
    height: 20px;
    width: 22px;
    transform: rotate(90deg);
}
.choose__button .choose__icon{
    width: 30px;
    height: 30px;
}
.choose__button .choose__icon_path{
 fill: #152839;
 transition: all .3s ease-in-out;
}

.time .checked .choose__icon_path{
    fill: #fff!important;
   }

.choose__button:hover .choose__icon_path{
    fill: #fff;
   }

.checked{
    background-color: #152839;
    color: #fff;
}
.checked .choose__icon__path{
    fill: #fff;
}

@media (min-width: 720px) {

    .cart{
        width: 375px;
        float: right;
        margin: 0 10px 10px 0;
    }
    .wide__column{
        width: 70%;
        padding: 0 10px 0 0;
    }

    .sidebar__column{
        width: 30%;
        padding: 0 0 0 10px;
    }
}