.accordion__section_sec {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
  }

.order__history__icon_path{
    fill: #444;
}

.order__history__icon{
    width: 50px;
    height: 50%;
    padding: 8px;
}

  /* Style the buttons that are used to open and close the accordion panel */
  .accordion_sec {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 8px 6px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
    box-shadow: none;
    margin: 0;
    border-radius: 0;
    background-color: #fff;
    border-bottom: 1px solid #00000021;

  }

  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .accordion_sec:hover,
  .active {
    background-color: #fff;
  }

  /* #1528391c */

  /* Style the accordion content title */
  .accordion__title {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
  }

  /* Style the accordion chevron icon */
  .accordion__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
  }

  /* Style to rotate icon when state is active */
  .rotate {
    transform: rotate(90deg);
  }

  /* Style the accordion content panel. Note: hidden by default */
  .accordion__content {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;
    margin-bottom: 0px;

  }
  .accordion__item_sec{
    display: flex;
    align-items: center;
    font-size: 18px;
    justify-content: center;
    padding: 15px 5px 15px 5px;
  }

  /* Style the accordion content text */
  .accordion__text {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 18px;
  }

.accordion__photo{
  max-width: 50px;
}

.accordion__photo_min{
  max-width: 50px;
}

  .accordion__price{
      margin-left: auto;
      color: #fff;
      background-color: #152839;
      border-radius: 50px;
      height: 45px;
      width: 45px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 10px 0 auto;
      box-shadow: 0 0 0 7px rgba(0,0,0,0.1);
      display: flex;
      font-weight: 600;
      flex-direction: column;
      transition: box-shadow 0.5s ease;
  }

.accordion__price:hover{
  box-shadow: 0 0 0 7px #28b8c14f;
}

.accordion__item_data_number{
  min-width: 45px;
  margin-left: 10px;
  margin-right: 20px;
  color: #fff;
  background-color: #152839;
  border-radius: 50px;
  height: 45px;
  width: 45px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 7px rgba(0,0,0,0.1);
  display: flex;
  font-weight: 600;
  flex-direction: column;
  transition: box-shadow 0.5s ease;
}
.accordion__item_data_list{
  margin-left: 20px;
  margin-right: 40px;
}

.accordion__item_data_button{
  margin: 0 10px 0 auto;
  background-color: #152839;
  border: solid 2px #152839;
  border-radius: 50px;
  transition: ease-in-out 0.3s;
  padding: 2px 20px;
  align-items: center;
  width: auto;
  display: flex;
}

.price__currency{
  font-size: 11px;
  letter-spacing: 0.1em;
  line-height: 1.4em;
}

  .items__image{
    border-radius: 50%;
  }

  .accordion__data_sec{
    padding: 0 0 0 10px;
    text-align: left;
  }

  .accordion__desc{
    font-weight: 500;
    font-size: 14px;
  }

/* 720px lub 960px */

  @media (min-width: 720px) {
    .accordion__price{
      height: 58px;
      width: 58px;
      font-size: 16px;
      font-weight: 600;
    }

    .accordion__photo{
      max-width: 78px;
    }

    .accordion__photo_min{
      max-width: 62px;
    }

    .accordion__title{
      font-size: 19px;
    }
    .accordion__desc{
      font-weight: 500;
      font-size: 16px;
    }

  }