
.header{
    position: absolute;
    top: 0px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}
.header__content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header__item{
    padding: 12px 0px;
    display: flex;
}