.cart{
    position: fixed;
    right: 0px;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 5px;
    z-index: 2;
}

.cart__container{
    padding: 6px;
    display: flex;
    flex-direction: column;
    background-color: #152839;
    width: 100%;
    color: #fff;
    border-radius: 35px;
    border: solid 2px #28b8c1;
    outline: none;
}
.activex{

}

.cart__big{
    transition: max-height 0.35s ease-in-out;
    overflow: hidden;
}
.cart__big__content{
    margin: 15px 10px 30px 10px;
}

.cart__small{
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.cart__icon__box{
    background-color: #28B8C1;
    border-radius: 50px;
    width: 50px;
    height: 50px;
}
.cart__icon{
    max-width: 50px;
    max-height: 50px;
    padding: 10px;
    fill: #152839;
}
.delete__icon{
    max-width: 20px;
    max-height: 30px;
    fill: #fff;
}

.cart__content{
    margin-left: 10px;
}
.cart__title{
    font-size: 18px;
    margin: 10px 0 0 10px;
}
.cart__order{

}
.cart__price{
    margin-left: auto;
    background-color: #28B8C1;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #152839;
    font-weight: 600;
}
.cart__money{

}
.cart__currency{

}

.button__cart{
    width: 100%;
    margin: 5px 0px 5px 0px;
    padding: 15px 20px;
    background-color: #28b8c1;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    -webkit-box-shadow: 0px 6px 18px 1px rgba(21,40,57,0.5);
    -moz-box-shadow: 0px 6px 18px 1px rgba(21,40,57,0.5);
    box-shadow: 0px 6px 18px 1px rgba(21,40,57,0.5);
    border: solid 4px #152839;
    border-radius: 30px;
    text-transform: uppercase;
    transition: all .3s ease-in-out;
}

.button__cart:hover{
    background-color: #fff;
    color: #152839;
}

.cart__products_checkout{
    display: flex;
    flex-direction: column;
    color:#152839;
}

.cart__products_checkout .cart__single__product{
    color: #152839;
}

.cart__products_checkout .change__amount{
    border: solid 1px #152839;
    color: #152839;
}

.cart__products{
    display: flex;
    flex-direction: column;
}

.cart__single__product{
    padding: 4px 0;
    font-size: 12px;
    color: #fff;
}

.cart__products_checkout .cart__row{
    border-bottom: 1px solid #1528392b;
    padding: 8px 0px 4px 0px;
}

.cart__row{
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: center;
    align-content: center;
    transform: translateZ(0);
    align-items: center;
    width: 100%;
}

.cart__products_checkout .promo_product{
    border-top: 2px dashed #28b8c182;
    border-bottom: none;
}

.cart__single__product .promo_product__cart{
    border-top: 1px dashed #fff;
    border-bottom: none;
}

.cart__product__amount{
    padding-right: 32px;
    width: 20px;
}

.cart__product__name{
    width: 100%;
    padding: 4px 0;
    word-break: break-all;
    word-break: break-word;
    flex: 1 1;
    font-size: 13px;
}

.cart__product__edit_amount{

}

.change__amount{
    width: auto;
    background-color: unset;
    box-shadow: none;
    border: solid 1px #fff;
    border-radius: 5px;
    line-height: 18px;
    margin: 0px 5px 0px 5px;
    padding: 0px 8px 0px 8px;
    transition: all .3s ease-in-out;
    cursor: pointer;
}

.cart__product__price{
    text-align: right;
    min-width: 3rem;
    white-space: nowrap;
    font-size: 13px;
    margin-left: 10px;

}

.cart__product__delete{
    width: auto;
    background-color: unset;
    box-shadow: none;
    border: none;
    margin: 0px;
    padding: 6px 0px 6px 6px;
    transition: all .3s ease-in-out;
    cursor: pointer;
}

.cart__product__delete:hover{
    background-color: unset;
    color: #fff;

}

.cart__promo{

}

.cart__summary{

}

.cart__summary__name{
    width: 100%;
    padding: 4px 0
}

.cart__summary__price{
    min-width: 155px;
    text-align: right;
    white-space: nowrap;
}

.destination__container{
    position: relative;
    margin: 10px;
}

.destination{
    width: 100%;
    padding: 13px 10px 14px 60px;
    border: none;
    margin-bottom: 2px;
    box-shadow: none;
    color: #fff;
    font-size: 14px;
    background-color: #152839;
    border: solid 2px #28b8c1;
    border-radius: 50px;
}

.destination::placeholder {
    color: #fff;
}

.destination__container .cart__icon__box{
    background-color: #28B8C1;
    border-radius: 50px;
    width: 45px;
    height: 45px;
    position: absolute;
    left: 0px;
    top: 1px;
}

.destination__container .cart__icon{
    max-width: 45px;
    max-height: 45px;
}

.cart__products_checkout .cart__product__name{
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0 20px;
}

.cart__products_checkout .cart__product__amount{
    font-size: 17px;
    font-weight: 600;
}

.cart__products_checkout .change__amount{
    font-size: 17px;
    font-weight: 600;
}

.cart__products_checkout .cart__product__price{
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
}

.form__control__checkout{
    width: 100%;
    padding: 13px 10px 14px 60px;
    border: none;
    margin-bottom: 2px;
    box-shadow: none;
    border-radius: 50px;

    color: #152839;
    font-size: 14px;
    background-color: #ffffff;
    border: solid 2px #152839;
}

@media (min-width: 720px) {

    .cart{
        width: 375px;
        float: right;
        right: 10px;
    }
}