.accordion__section {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  /* Style the buttons that are used to open and close the accordion panel */
  .accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 8px 6px;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
    box-shadow: none;
    margin: 0;
    border-radius: 0;
    background-color: #fff;
    border-bottom: 1px solid #00000021;

  }

  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  .accordion:hover,
  .active {
    background-color: #28b8c182;
  }

  /* #1528391c */

  /* Style the accordion content title */
  .accordion__title {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
  }

  .accordion__item__title{
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 15px;
    text-transform: uppercase;
  }

  /* Style the accordion chevron icon */
  .accordion__icon {
    margin-left: auto;
    transition: transform 0.6s ease;
  }

  /* Style to rotate icon when state is active */
  .rotate {
    transform: rotate(90deg);
  }

  /* Style the accordion content panel. Note: hidden by default */
  .accordion__content {
    background-color: white;
    overflow: hidden;
    transition: max-height 0.6s ease;
    margin-bottom: 0px;

  }
  .accordion__item{
    display: flex;
      align-items: center;
    padding: 15px 15px 10px 15px;
  }

  /* Style the accordion content text */
  .accordion__text {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding: 18px;
  }

.accordion__photo{
  max-width: 50px;
}

.accordion__photo_min{
  max-width: 50px;
  min-width: 50px;
}

  .accordion__price{
      margin-left: auto;
      color: #fff;
      background-color: #152839;
      border-radius: 50px;
      height: 45px;
      width: 45px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 10px 0 auto;
      box-shadow: 0 0 0 7px rgba(0,0,0,0.1);
      display: flex;
      font-weight: 600;
      flex-direction: column;
      transition: box-shadow 0.5s ease;
      min-width: 45px;
  }

.accordion__price:hover{
  box-shadow: 0 0 0 7px #28b8c14f;
}

.price__currency{
  font-size: 11px;
  letter-spacing: 0.1em;
  line-height: 1.4em;
}

  .items__image{
    border-radius: 50%;
  }

  .accordion__data{
    padding: 0 0 0 10px;
  }

  .accordion__desc{
    font-weight: 500;
    font-size: 14px;
  }

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #152839;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip-left {
  top: 10px;
  bottom: auto;
  right: 118%;
}
.tooltip-left::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #152839;
}
/* 720px lub 960px */

  @media (min-width: 720px) {
    .accordion__price{
      height: 58px;
      width: 58px;
      font-size: 16px;
      font-weight: 600;
    }

    .accordion__photo{
      max-width: 78px;
    }

    .accordion__photo_min{
      max-width: 62px;
    }

    .accordion__title{
      font-size: 18px;
    }
    .accordion__item__title{
      font-size: 15px;
      font-weight: 600;
    }
    .accordion__desc{
      font-weight: 500;
      font-size: 15px;
    }
    .accordion__item{
      padding: 15px 30px 10px 30px;
    }
    .accordion__data{
      padding: 0 0 0 20px;
    }
  }