.alert__box{

}
.alert{
    border: solid 1px #000;
    font-size: 15px;
    border-radius: 50px;
    padding: 1px 7px;
    display: flex;
    font-weight: 600;
    align-items: center;
    color: #fff;
}

.alert__success{
    background-color: #15CD72;
    border-color: #15CD72;
}

.alert__info{
    background-color: #4bb1cf;
    border-color: #4bb1cf;
}

.alert__warning{
    background-color: #eebf31;
    border-color: #eebf31;
}

.alert__error{
    background-color: #ED4F32;
    border-color: #ED4F32;
}

.alert__icon_path{
    fill: #fff;
}

.alert__icon{
    width: 45px;
    height: 45px;
    padding: 1px 10px 1px 0px;
}