.hero{
    align-items: center;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 30%, rgba(255,255,255,0) 41%), url(../../images/hero5.jpg);
    background-size: cover;
    padding: 70px 20px 20px 20px;
}

.hero__logo{
    margin: auto;
    display: flex;
    justify-content: center;
    width: 155px;
    height: 155px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 100px;
    align-items: center;
}

.hero__title{
    color: #fff;
    text-align: center;
    margin: 15px 0;
    line-height: 1.2;
    font-weight: 600;
}

.hero__title--small{
    font-size: 14px;
}

.hero__heading{
    display: flex;
    justify-content: center;
    align-items: center;

}

.hero__logo_img{
    width: 100%;
    padding: 15px;
}

.hero__choose{
    text-align: center;

}

.dropdownMenu{
    width: fit-content;
    margin: auto;
}
.dropdownMenu__button{
    margin: 0px;
    padding: 8px 20px;
    border-radius: 20px;
    display: inline-flex;
}

.dropdownMenu__button:hover,
.active {
  background-color: #fff;
  color :#152839;
}
.dropdownMenu__button:hover
.dropdownMenu__icon{
    fill: #152839;
}

.dropdownMenu__options{
    background-color: #fff;
    border-radius: 0px 0px 10px 10px;
    display: block;
    border: solid 2px #152839;
    overflow: hidden;
    transition: max-height 0.6s ease;
    margin-bottom: 0px;
}
.dropdownMenu__option{
    cursor: pointer;
    padding: 4px;
    font-weight: 600;
    transition: background-color 0.3s ease;
    overflow: hidden;
    border-radius: 10px;
}

.dropdownMenu__icon,
.active{
    fill: #152839;
}
.dropdownMenu__icon{
    fill: #fff;
    transition: transform 0.6s ease;
}

.dropdownMenu__option:hover{
    background-color: rgb(211, 211, 211);
}

  /* Style to rotate icon when state is active */
  .rotate {
    transform: rotate(90deg);
  }
@media (max-width: 720px) {
    .hero__logo{
        opacity: 1;
    }
}